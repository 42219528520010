import { defineStore } from 'pinia';
import { get, post, del } from 'SRC/api/index.js';
import { useUsersStore } from 'SRC/piniaStore/users/users';
import { useAppStore } from '../app/app';
import { evaluateFormula } from './utils';
import { useEditorStore } from '../editor/editor';

const initialState = () => ({
  datasetElements: {},
  next: null,
  selectedDatasetElements: []
});

export const useDataStore = defineStore('data', {
  state: initialState,
  actions: {
    async fetchDatasetElements(dataTypeIds = [], hasNextPage = false, isDataTable = true, sortAttribute = '') {
      let response;
      if (hasNextPage) {
        response = await get(this.next, null, true, false);
      } else {
        if (dataTypeIds.length > 0) {
          let query = dataTypeIds.map((id) => `dataTypeIds=${id}`).join('&');
          if (sortAttribute) {
            query += `&sortAttribute=${sortAttribute}`;
          }
          response = await get(`/dataset-elements?${query}&page=1&size=25`);
        }
      }
      if (!response) {
        return { error: 'Error occured in fetchDatasetElements' };
      }

      const datasetElements = {};
      response.data.forEach((datasetElement) => {
        datasetElements[datasetElement.id] = datasetElement;
      });
      if (isDataTable) {
        this.datasetElements = { ...this.datasetElements, ...datasetElements };
        this.next = response.next;
        const ids = response.data.map((el) => el.id);
        return ids;
      }
      const appStore = useAppStore();
      appStore.levels = { ...appStore.levels, ...datasetElements };
      this.next = response.next;
      const ids = response.data.map((el) => el.id);
      return ids;
    },
    reset() {
      Object.assign(this.$state, initialState());
    },
    async loadMoreDatasetElements(isSearchActive, searchValue) {
      let ids = [];
      if (!this.next) {
        return ;
      }
      if (isSearchActive) {
        ids = await this.searchDatasetElements(searchValue.query, searchValue.attributes, true);
      } else {
        ids = await this.fetchDatasetElements([], true);
      }
      return ids;
    },
    addSelectedDatasetElements(id) {
      this.selectedDatasetElements = [
        ...new Set([...this.selectedDatasetElements, ...id])];
    },
    removeSelectedDatasetElements(elementId) {
      this.selectedDatasetElements = this.selectedDatasetElements.filter(
        (id) => id !== elementId
      );
    },
    clearSelectedDatasetElements() {
      this.selectedDatasetElements = [];
    },
    async searchDatasetElements(query, attributes = {}, hasNextPage = false) {
      const body = {
        attributes
      };
      const filteredDatasetElements = {};
      let response = null;
      try {
        if (hasNextPage) {
          response = await post(this.next, body, true, false);
        } else {
          response = await post(`/dataset-elements/search-requests?search=${query}&page=1&size=25`, body);
        }
        if (!response || !response.data) {
          return [];
        }
        this.next = response.next;
        response.data.forEach((datasetElement) => {
          filteredDatasetElements[datasetElement.id] = datasetElement;
        });
        this.datasetElements = hasNextPage ? {...this.datasetElements, ...filteredDatasetElements} : filteredDatasetElements;
        const ids = response.data.map((el) => el.id);
        return ids;
      } catch (error) {
        return [];
      }
    },
    async deleteAllSelectedDatasetElements(ids) {
      try {
        const response = await del(`/dataset-elements?ids=${ids}`);
        if (response?.data?.length > 0) {
          response.data.forEach((el) => {
            delete this.datasetElements[el.id];
          });
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    async deleteSelectedDatasetElement(id) {
      try {
        const result = await del(`/dataset-elements/${id}`);
        if (result && result.attributes) {
          this.selectedDatasetElements = this.selectedDatasetElements.filter((el) => el !== result.attributes['friendly-id']);
          delete this.datasetElements[id];
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    async deleteDatasetElement(id) {
      try {
        const result = await del(`/dataset-elements/${id}`);
        if (result) {
          delete this.datasetElements[id];
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    async fetchRelations(datasetElementIds) {
      let query = datasetElementIds
        .map((id) => `datasetElementIds=${id}`)
        .join('&');
      try {
        const response = await get(`/hierarchical-relations?${query}`);
        if (!response) {
          return;
        }
        const datasetElementsWithChildren = response.data.filter(
          (el) => el.datasetElementChildrenIds.length > 0
        );
        if (datasetElementsWithChildren.length === 0) {
          return { childrenData: [], datasetElementsWithChildren: []};
        }
        const childrenIds = datasetElementsWithChildren.reduce((acc, el) => [...acc, ...el.datasetElementChildrenIds], []);
        query = childrenIds.map((id) => `ids=${id}`).join('&');
        const childrenData = await get(`/dataset-elements?${query}`);
        const returnedValue = {
          childrenData: childrenData.data,
          datasetElementsWithChildren
        };
        return returnedValue;
      } catch (error) {
        console.error('Error in fetchRelations', error);
      }
    },
    async fetchRelatedDatasetElements(id) {
      const response = await get(`/dataset-elements/${id}/relations`);
      const relatedDatasetElementIds = response.data.map((el) => el.relatedDatasetElementId);
      if (relatedDatasetElementIds.length) {
        const query = relatedDatasetElementIds.map((id) => `ids=${id}`).join('&');
        const relatedDatasetElementsData = await get(`/dataset-elements?${query}`);
        return { id, dependencies: relatedDatasetElementsData.data };
      }
    },
    async fetchDatasetElementsDependencies(datasetElementIds) {
      try {
        const data = await Promise.all(datasetElementIds.map((id) => this.fetchRelatedDatasetElements(id)));
        return data.filter((el) => el && el.dependencies.length > 0);
      } catch (error) {
        console.error('Error in fetchDatasetElementsDependencies', error);
      }
    },
    associateChildrenData(childrenData, datasetElementsWithChildren) {
      const datasetElementsEntries = Object.entries(this.datasetElements);
      const updatedDatasetElements = datasetElementsEntries.reduce(
        (acc, [dsId, dsValue]) => {
          const hasChildren = datasetElementsWithChildren.find(
            (dse) => dse.datasetElementId === dsId
          );
          if (!hasChildren) {
            return {
              ...acc,
              [dsId]: dsValue
            };
          } else {
            const childrenIds = hasChildren.datasetElementChildrenIds;
            const children = childrenData.filter(
              (element) => childrenIds.indexOf(element.id) > -1
            );
            return {
              ...acc,
              [dsId]: { ...dsValue, children }
            };
          }
        },
        {}
      );
      this.datasetElements = updatedDatasetElements;
    },
    associateDependenciesData(dependencies) {
      const datasetElementsEntries = Object.entries(this.datasetElements);
      const updatedDatasetElements = datasetElementsEntries.reduce(
        (acc, [dsId, dsValue]) => {
          const hasDependencies = dependencies.find((d) => d.id === dsId);
          if (!hasDependencies) {
            return {
              ...acc,
              [dsId]: { ...dsValue }
            };
          } else {
            const dependenciesData = hasDependencies.dependencies;
            return {
              ...acc,
              [dsId]: { ...dsValue, dependencies: dependenciesData }
            };
          }
        },
        {}
      );
      this.datasetElements = updatedDatasetElements;
    },
    async addFormulasResultsToElements() {
      const appStore = useAppStore();
      if (!(appStore.formulas && Object.values(appStore.formulas).length > 0)) {
        return;
      }
      const datasetElementsValues = Object.values(this.datasetElements);
      const computedAttributesByType = Object.values(appStore.datatypes).reduce(
        (acc, { attributes, id }) => ({
          ...acc,
          [id]: attributes.filter((attr) => attr.computedByFormula)
        }),
        {}
      );
      const dataTypes = appStore.datatypes;
      const editorStore = useEditorStore();
      const updatedDatasetElementsPromises = datasetElementsValues.map(
        async (dsValue) => {
          if (!dsValue.children && !dsValue.dependencies) {
            return {
              [dsValue.id]: dsValue
            };
          } else {
            const dataType = Object.values(appStore.datatypes).find(
              (el) => el.id === dsValue.typeId
            );
            const attributes = { ...dsValue.attributes };
            const computedAttributes = computedAttributesByType[dataType.id];
            const kpiDataTypeId = Object.values(dataTypes).find((el) => el.name === 'KPI').id;
            for (const attr of computedAttributes) {
              const formula = appStore.formulas[attr.computedByFormula];
              if (dsValue.dependencies) {
                dsValue.dependencies = dsValue.dependencies.filter((dependency) => !(dependency.typeId === kpiDataTypeId
                && !dependency.attributes.targetValueComparator));
              }
              let formulaResult = await evaluateFormula(
                dsValue,
                dataType,
                formula.expression,
                dataTypes
              );
              if (typeof formulaResult === 'number' && formulaResult.toString() === 'NaN') {
                formulaResult = 0;
              }
              if (formulaResult instanceof Error) {
                formulaResult = 0;
              }
              attributes[attr.name] = parseFloat((Math.round(formulaResult * 100) / 100).toFixed(2)
                .toString().replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, ''));
            }
            return {
              [dsValue.id]: { ...dsValue, attributes }
            };
          }
        }
      );
      const updatedDatasetElementsArray = await Promise.all(
        updatedDatasetElementsPromises
      );
      const updatedDatasetElements = Object.assign(
        {},
        ...updatedDatasetElementsArray
      );
      this.datasetElements = updatedDatasetElements;
      const isEditorOpen = editorStore.isEditorOpen;
      const isSelectedDatasetElement = editorStore.selectedDatasetElement && editorStore.selectedDatasetElement.id;
      if (isEditorOpen && isSelectedDatasetElement && this.datasetElements[editorStore.selectedDatasetElement.id]) {
        editorStore.selectedDatasetElement = this.datasetElements[editorStore.selectedDatasetElement.id];
      }
    },
    async addComputedProperties(elementsIds) {
      try {
        const { childrenData, datasetElementsWithChildren } =  await this.fetchRelations(elementsIds);
        this.associateChildrenData(childrenData, datasetElementsWithChildren);
      } catch (error) {
        console.error('Error in addComputedProperties', error);
      }
      try {
        const dependencies = await this.fetchDatasetElementsDependencies(elementsIds);
        this.associateDependenciesData(dependencies);
      } catch (error) {
        console.error('Error in addComputedProperties', error);
      }
      try {
        const appStore = useAppStore();
        const formulasExist =
          appStore.formulas && Object.values(appStore.formulas).length > 0;
        if (!formulasExist) {
          await appStore.fetchFormulas();
        }
        await this.addFormulasResultsToElements();
      } catch (error) {
        console.error('Error in addComputedProperties', error);
      }
    },
    updateAttributeInParent(elementId, attributes) {
      const parent = Object.values(this.datasetElements).find(
        (dsValue) =>
          dsValue.children &&
          dsValue.children.length > 0 &&
          dsValue.children.find((child) => child.id === elementId)
      );
      if (!parent) {
        return;
      }
      this.datasetElements = {
        ...this.datasetElements,
        [parent.id]: {
          ...parent,
          children: parent.children.map((element) => {
            if (!(element.id === elementId)) {
              return element;
            }
            return {
              ...element,
              attributes: { ...element.attributes, ...attributes }
            };
          })
        }
      };
    },
    updateAttributeInRelatedDatasetElements(elementId, attributes) {
      const relatedDatasetElement = Object.values(this.datasetElements).find(
        (dsValue) =>
          dsValue.dependencies &&
          dsValue.dependencies.length > 0 &&
          dsValue.dependencies.find((dependency) => dependency.id === elementId)
      );
      if (!relatedDatasetElement) {
        return;
      }
      this.datasetElements = {
        ...this.datasetElements,
        [relatedDatasetElement.id]: {
          ...relatedDatasetElement,
          dependencies: relatedDatasetElement.dependencies.map((element) => {
            if (element.id !== elementId) {
              return element;
            }
            return {
              ...element,
              attributes: { ...element.attributes, ...attributes }
            };
          })
        }
      };
    },
    updateElementChildren(children, elementId) {
      this.datasetElements[elementId] = {
        ...this.datasetElements[elementId],
        children
      };
    },
    updateElementDependencies(dependencies, elementId) {
      this.datasetElements[elementId] = {
        ...this.datasetElements[elementId],
        dependencies
      };
    },
    async createDatasetElement(body) {
      const usersStore = useUsersStore();
      const appStore = useAppStore();
      const dataTypeName = appStore.datatypes[body.typeId].name;
      body = {
        ...body,
        attributes: {
          ...body.attributes,
          owner: usersStore.currentUser.id,
          ...(dataTypeName === 'Objective'
            ? { driver: 'Sub-objective', status: 'Not started' }
            : {})
        }
      };
      const datasetElement = await post('/dataset-elements', body);
      this.datasetElements = {
        [datasetElement.id]: datasetElement,
        ...this.datasetElements
      };
      return datasetElement;
    },
    updateDatasetElements({attributes, id}) {
      const datasetElement = this.datasetElements[id];
      this.datasetElements = { ...this.datasetElements,
        [id]: {
          ...datasetElement,
          attributes: {...datasetElement.attributes, ...attributes}
        }};
    },
    async fetchDatasetElementById(id) {
      try {
        const datasetElement = await get(`/dataset-elements/${id}`);
        if (!datasetElement) {
          return ;
        }
        return datasetElement;
      } catch (error) {
        console.error('Error in fetchDatasetElementById', error);
      }
    }
  }
});
