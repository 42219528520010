<template>
  <div>
    <slot
      :err="err"
      :vm="vm"
      :info="info"
    />
    <dialog-box
      v-if="appStore && appStore.errors.length"
      class="z-50"
      :dialog-box-specific-style="dialogBoxStyle"
      :no-padding="true"
    >
      <pop-up
        :details-object="appStore.errors[0]"
        title="Something went wrong"
        @popup-button-click="handleError"
      />
    </dialog-box>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { API_HOST } from 'SRC/config/api';
import { useAppStore } from 'SRC/piniaStore/app/app';

export default {
  name: 'ErrorHandler',
  props: {
    stopPropagation: Boolean
  },
  data() {
    return {
      dialogBoxStyle: 'position: absolute; left: 35%; top: 35%; width: 35%;',
      err: null,
      vm: null,
      info: null,
      API_HOST
    };
  },
  computed: {
    ...mapStores(useAppStore)
  },
  methods: {
    handleError() {
      const errors = this.appStore.errors;
      errors.shift();
      this.appStore.setErrors(errors);
      if (errors.length === 0) {
        this.err = false;
      } else {
        this.err = true;
      }
    }
  }
};
</script>
