
export const UNASSIGNED = 'Unassigned';

export const iconSizesMap = {
  'xsmall': '12',
  'small': '16',
  'default': '20',
  'large': '24',
  'xl': '28',
  '2xl': '32',
  '3xl': '48',
  '4xl': '64'
};

export const iconSizes = [
  'xsmall',
  'small' ,
  'default',
  'large' ,
  'xl' ,
  '2xl',
  '3xl'
];

export const avatarSizesMap = [
  'xsmall',
  'small',
  'default',
  'large',
  'xl',
  '2xl',
  '3xl',
  '4xl'
];

export const defaultBadgeColors = {
  'Not started': 'secondary',
  'Off track': 'alert',
  'On track': 'primary',
  'Achieved': 'success',
  'Draft': 'secondary',
  'None': 'secondary',
  'In progress': 'primary',
  'New': 'secondary',
  'Canceled': 'secondary',
  'Active': 'primary',
  'At risk': 'warning',
  'Delay': 'alert',
  'High': 'alert',
  'Low': 'secondary',
  'Medium': 'primary',
  'Paused': 'secondary',
  'Closed': 'success',
  'Done': 'success',
  'Reached': 'success',
  'Critical': 'alert',
  'Planning': 'secondary',
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const dataTypesIcons = {
  Objective: {
    types: ['CI Goal', 'CBN', 'Foundational Element'],
    icons: {
      parent: 'Goal',
      children: 'Goal'
    },
    color: 'other'
  },
  Project: {
    icons: {
      parent: 'FolderOpen',
      children: 'FolderOpen'
    },
    color: 'primary'
  },
  Issue: {
    icons: {
      parent: 'AlertTriangle',
      children: 'AlertTriangle'
    },
    color: 'alert'
  },
  Task: {
    icons: {
      parent: 'ClipBoardList',
      children: 'ClipBoardList'
    },
    color: 'success'
  },
  KPI: {
    icons: {
      parent: 'TrendingUp',
      children: 'TrendingUp'
    },
    color: 'secondary'
  },
  Action: {
    icons: {
      parent: 'MousePointerClick',
      children: 'MousePointerClick'
    },
    color: 'warning'
  }
};

export const RELATION_TYPES = ['parent', 'children', 'dependency'];

export const RELATION_LEFT_ATTRIBUTES_MAP = {
  'friendly-id': 'title',
  'title': 'text'
};

export const RELATION_RIGHT_ATTRIBUTES_MAP = {
  'progress': 'progress',
  'status': 'status',
  'owner': 'user'
};

export const RELATION_DETAILS_MENU_ITEMS = [
  { id:'deleteAction', text: 'Delete element', type: 'menu' },
  { id:'unlinkAction', text: 'Unlink', type: 'menu' }
];

export const containerSizeMap = {
  xsmall: 'var(--list-element-asset-sizing-xs-width)',
  small: 'var(--list-element-asset-sizing-s-width)',
  default: 'var(--list-element-asset-sizing-default-width)'
};

export const iconsNames = [
  'BarChart4',
  'Rows3',
  'PowerBI',
  'Embed',
  'BarChart',
  'ArrowRight',
  'CalendarRange',
  'CalendarCheck',
  'Minus',
  'Clock',
  'PaperClip',
  'ClipBoardList',
  'AlertTriangle',
  'FilePenLine',
  'FolderOpen',
  'FolderClose',
  'AlertTriangle',
  'Goal',
  'TrendingUp',
  'Target',
  'AlertCircle',
  'Info',
  'Link',
  'LogoType',
  'LogoFont',
  'ArrowUpRight',
  'Globe',
  'UserPlus',
  'FileUp',
  'Calendar',
  'CalendarDays',
  'MoreHorizontal',
  'Watch',
  'ArrowUp',
  'ArrowDown',
  'RightArrow',
  'Check',
  'PlusCircle',
  'BookMark',
  'Bubble',
  'Card',
  'X',
  'Chip',
  'SquarePen',
  'StrokeStyle',
  'ColoredChip',
  'Repeat',
  'StrokeDash',
  'MinimumStroke',
  'MediumStrokeFilled',
  'MaximumStrokeFilled',
  'StrokeDot',
  'XSquare',
  'Board',
  'CreditCard',
  'ChevronDown',
  'CardFields',
  'Home',
  'Room',
  'History',
  'Smile',
  'StarFilled',
  'VerticalLine',
  'ChevronRight',
  'Function',
  'LinkMode',
  'ChvevronsUpDown',
  'Text',
  'ChevronUp',
  'MoreVertical',
  'Hand',
  'Lock',
  'ALargeSmall',
  'Dot',
  'Hash',
  'MousePointer',
  'Search',
  'Ban',
  'ChevronDownCircle',
  'Square',
  'Users',
  'Eye',
  'EyeOff',
  'Network',
  'Plus',
  'Trash',
  'Star',
  'User',
  'iObeyaLogo',
  'Spinner',
  'xCircleFilled',
  'Bell',
  'Redo',
  'Undo',
  'ArrowLeft',
  'Menu',
  'LayoutTemplate',
  'ArrowDownWideNarrow',
  'ListFilter',
  'List',
  'LayoutGrid',
  'LayoutMural',
  'Group',
  'Unplug',
  'TicketCheck',
  'ArrowDownLeft',
  'settings-2',
  'CheckCircle-2',
  'CheckCircle-2-Filled',
  'PanelTopLeft',
  'MousePointerClick',
  'Newspaper',
  'Blocks',
  'Keyboard',
  'LinkedinOriginal',
  'LinkedinDark',
  'ExternalLink',
  'Droplet',
  'Image',
  'CloudUpload',
  'Ustensils',
  'UstensilsCrossed',
  'University',
  'TestTubeDiagonal',
  'Upload',
  'GitFork',
  'ArrowUpRightFromSquare',
  'ThumbUp',
  'Unlock',
  'Megaphone',
  'BookA',
  'ChevronLeft',
  'ChevronsLeft',
  'ChevronsRight',
  'Download',
  'Qcd',
  'RefreshCcwDot',
  'RefreshCw',
  'Split',
  'PersonWalking',
  'Gauge',
  'Route',
  'BadgeCheck',
  'CircuitBoard',
  'ScrollText',
  'Crown',
  'Carrot',
  'CakeSlice',
  'UsersRound',
  'AppWindowMac',
  'messageCircleQuestion',
  'MinusCircle',
  'CalendarClock',
  'KeyRound',
  'Plug',
  'MediumNote',
  'RectangleHorizontal',
  'PanelBottom',
  'LayoutCardResizable'
].sort();

export const COLOR_PALETTE = [
  '#D0D0D0',
  '#F3B1D1',
  '#D2BBFB',
  '#B1D1F7',
  '#C8E999',
  '#FFE97B',
  '#FFC179',
  '#FF8C75',
  '#FFC4B7',
  '#FFDEB8',
  '#FFF3B9',
  '#E1FDB9',
  '#D7E8FD',
  '#EBE2FD',
  '#FFE1F0',
  '#FFFFFF',
  '#FF8C75',
  '#FFC179',
  '#FFE97B',
  '#C8E999',
  '#B1D1F7',
  '#D2BBFB',
  '#F3B1D1',
  '#D0D0D0',
  '#FB593E',
  '#FFA444',
  '#FFDF48',
  '#AFD67A',
  '#8CB9F1',
  '#B997FA',
  '#E784B3',
  '#9E9E9E',
  '#F72B18',
  '#FF8822',
  '#FFD62E',
  '#97C35F',
  '#6AA2EC',
  '#A175FA',
  '#D95A96',
  '#2D2D2D'
];

export const MAX_HEIGHT_HIDDEN_AVATARS = 300;
export const MAX_ELEMENTS_IN_DROPDOWN_LIST = 6;
export const SHOW_COUNTER_ON_LENGTH = 15000 - 300;
export const ROUTINE_ICONS = [
  { name:'Qcd',  color: 'success', backgroundImg: '02' },
  { name: 'RefreshCcwDot', color: 'info', backgroundImg: '02' },
  { name: 'Split', color: 'warning', backgroundImg: '02' },
  { name: 'PersonWalking', color: 'other', backgroundImg: '02' },
  { name: 'TrendingUp', color: 'alert', backgroundImg: '02' },
  { name: 'Gauge', color: 'secondary', backgroundImg: '01' },
  { name: 'Route', color: 'other', backgroundImg: '01' },
  { name: 'BadgeCheck', color: 'success', backgroundImg: '02' },
  { name: 'CircuitBoard', color: 'info', backgroundImg: '01' },
  { name: 'ArrowUpRightFromSquare', color: 'primary', backgroundImg: '02' }
];

export const CONTEXTUAL_MENU_ICONS = [
  { name:'PanelBottom', backgroundImg: 'none', color: '#FFF' },
  { name: 'LayoutCardResizable', backgroundImg: 'none', color: '#FFF' },
];

export const ICON_SHAPE_SIZES_MAP = {
  xsmall: 24,
  small: 28,
  large: 36,
  xl: 40,
  '2xl': 48,
  '3xl': 64,
  '4xl': 96,
  default: 32,
};
