/**
 * Calculates the difference in days between two dates.
 * @param {Date} date1 The Starting date.
 * @param {Date} date2 The end date.
 * @returns {number} The difference in days between the two dates.
 */
export const datesDiffInDays = (date1, date2) => {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  return Math.abs(Math.floor((utc2 - utc1) / MS_PER_DAY));
};

/**
 * Formats a date relative to the current date.
 *
 * @param {Date} date - The date to format.
 * @returns {string} The formatted relative date.
 */
export const formatRelativeDate = (date) => {
  const currentDate = new Date();
  const diffDays = datesDiffInDays(date, currentDate);
  if (diffDays === 0) {
    return 'today';
  } else if (diffDays === 1) {
    return 'yesterday';
  } else {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  }
};

/**
 * Checks if a given string is a valid date.
 *
 * @param {string} str - The string to be checked.
 * @returns {boolean} - Returns `true` if the string is a valid date, otherwise returns `false`.
 */
export const isDate = (str) => {
  // Try parsing the string as a date
  const date = new Date(str);
  // Check if the parsed date is valid and not equal to "Invalid Date"
  return !isNaN(date) && date.toString() !== 'Invalid Date';
};

/**
 * Activity details based on modification and creation dates.
 * @typedef {Object} ActivityDetails
 * @property {string} formatedDate - The formatted date.
 * @property {string} activityStatus - The activity status ('edited' or 'created').
 */

/**
 * Returns the activity details based on modification and creation dates.
 *
 * @param {string} modificationDate - The modification date.
 * @param {string} creationDate - The creation date.
 * @returns {ActivityDetails} - The activity details object.
 */
export const getActivity = (modificationDate, creationDate) => {
  const formatedDate = formatRelativeDate(new Date(modificationDate));
  const datesDifference = new Date(modificationDate) - new Date(creationDate);
  const activityStatus = datesDifference > 1000 ? 'edited' : 'created';
  return { formatedDate, activityStatus };
};

/**
 * Returns the user's initials.
 * @param {String} firstname The user's firstname
 * @param {String} lastname The user's lastname
 * @returns {String} The user's initials
 */
export function getUserInitials(firstname, lastname) {
  return `${(firstname || '').charAt(0).toUpperCase()}${(lastname || '').charAt(0).toUpperCase()}`;
}

/**
 * Checks if two objects have the same keys and values.
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @returns {boolean} - Returns true if both objects have the same keys and values, false otherwise.
 *
 * @example
 * // returns true
 * objectsAreEqual({a: 1, b: 2}, {a: 1, b: 2});
 *
 * @example
 * // returns false
 * objectsAreEqual({a: 1, b: 2}, {a: 1, b: 3});
 *
 * @example
 * // returns false
 * objectsAreEqual({a: 1, b: 2}, {a: 1, c: 2});
 */
export function objectsAreEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

/**
 * Compares the values of common keys between two objects.
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @returns {boolean} - Returns true if all common keys have the same values, false otherwise.
 */
export function compareCommonKeys(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const commonKeys = keys1.filter((key) => keys2.includes(key));
  for (const key of commonKeys) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}
