export const initializeHierarchyColors = (hierarchy, colors, index = 0, isFirstLevel = true) => {
  if (!hierarchy) {
    return;
  }
  if (isFirstLevel) {
    hierarchy.attributes.colors = colors[index % colors.length];
    index++;
  }
  hierarchy.children.forEach((child) => {
    child.attributes.colors = colors[index % colors.length];
    initializeHierarchyColors(child, colors, index + 1, false);
  });
};

const utils = {
  debounce: (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  },
  initializeHierarchyColors
};
export default utils;
