<template>
  <div>
    <error-handler>
      <fetch-data-wrapper>
        <router-view />
      </fetch-data-wrapper>
    </error-handler>
    <loading-page-layer
      v-if="!usersStore.currentUser && !appStore.circuitOpened "
      size="extraLarge"
      spinner-color="blue"
    />
    <exception-handler />
  </div>
</template>

<script>
import { RouterView  } from 'vue-router';
import ErrorHandler from 'SRC/utils/ErrorHandler/ErrorHandler.vue';
import ExceptionHandler from 'SRC/utils/ExceptionHandler/ExceptionHandler.vue';
import FetchDataWrapper from './FetchDataWrapper/FetchDataWrapper.vue';
import { userCircuitBreaker, vmsCircuitBreaker } from 'SRC/api';
import { useUsersStore } from 'SRC/piniaStore/users/users';
import { useAppStore } from 'SRC/piniaStore/app/app';
import { mapStores } from 'pinia';

export default {
  name: 'App',
  components: {
    RouterView,
    ErrorHandler,
    ExceptionHandler,
    FetchDataWrapper
  },
  data() {
    return {
      userCircuit: null,
      vmsCircuit: null
    };
  },
  computed: {
    ...mapStores(useUsersStore, useAppStore)
  },
  created() {
    this.userCircuit = userCircuitBreaker;
    this.vmsCircuit = vmsCircuitBreaker;
  },
  async mounted() {
    this.vmsCircuit.on('open', () => {
      console.log('vmsCircuit : open');
      this.appStore.setCircuitOpened(true);
      this.$router.push({path: '/unavailableService'});
    });
    this.userCircuit.on('open', () => {
      console.log('userCircuit : open');
      this.appStore.setCircuitOpened(true);
      this.$router.push({path: '/unavailableService'});
    });
    this.userCircuit.on('halfOpen', async () => {
      console.log('userCircuit : halfOpen');
      await this.usersStore.fetchAllUsers();

    });
    this.vmsCircuit.on('halfOpen', async () => {
      console.log('vmsCircuit : halfOpen');
      await this.vmsCircuit.fire('/boards?page=1&size=25');
    });
  },
  updated() {
    const currentPath = this.$route.path;
    this.vmsCircuit.on('close', () => {
      if (currentPath === '/unavailableService') {
        this.$router.push({path: '/'});
      }
    });
    this.userCircuit.on('close', () => {
      if (currentPath === '/unavailableService') {
        this.$router.push({path: '/'});
      }
    });
  }
};
</script>

<style>
body {
  margin: 0;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  touch-action: none;
  overflow: hidden;
  user-select: none;
}

:root {
  --transparent: #e5e5e5;
  --light: #fff;
  --dark: #14131b;
  --dark--10: rgb(20 19 27 / 10%);
  --dark--20: rgb(20 19 27 / 20%);
  --neutral-400: #999b9e;
  --neutral-700: #595f63;
  --neutral-900: #323a3e;
  --caution-50: #fff0f1;
  --caution-200: #feb3b9;
  --caution-400: #f2737f;
  --caution-600: #c34652;
}
</style>
