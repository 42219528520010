<template>
  <div>
    <div>
      <div class="UnavailableService-logoContent">
        <img
          src="SRC/assets/icons/Dropdown.svg"
          alt="company-logo"
        >
      </div>
    </div>
    <div class="UnavailableService-body">
      <div class="UnavailableService-messageContent">
        <span class="UnavailableService-subtitle">
          iObeya is temporarily unavailable
        </span>
        <p class="UnavailableService-description">
          Sorry, we are currently experiencing technical issues. Don’t worry, we
          will be back in a few minutes.
        </p>
      </div>

      <custom-button
        class="UnavailableService-retryButton"
        color="WHITE"
        size="small"
        label="Try again"
        icon-name="History"
        icon-style="width:20px;height:20px"
        @click.prevent="retry"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'UnavailableServiceView',
  methods: {
    async retry() {
      window.location = '/boards';
    }
  }
};
</script>
<style src="./UnvailableService.css" />

