<template>
  <div
    :id="content['level-id']"
    class="apex-tree-node node-container"
  >
    <div class="node-header">
      <div class="node-name">
        {{ nodeDetails.levelName }}
      </div>
    </div>
    <div
      class="node-structure"
      :style="{backgroundColor: nodeDetails.colors.bgColor, color: nodeDetails.colors.textColor}"
    >
      {{ nodeDetails.organisationalStructure }}
    </div>
    <div class="node-details">
      {{ `${membersCount} members` }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useUsersStore } from 'SRC/piniaStore/users/users';

const usersStore = useUsersStore();
const users = computed(() => usersStore.users);

const props = defineProps({
  content: {
    type: Object,
    required: true
  }
});

const nodeDetails = computed(() => {
  const { 'organisational-structure': organisationalStructure, members, colors, 'level-name': levelName } = props.content;
  return { organisationalStructure, members, colors, levelName };
});

const isValidUser = (user, value, attribute) => user[attribute] && (user[attribute] === value || user[attribute].split('@')[0] === value);

const getUser = (value) => users.value.find((user) => isValidUser(user, value, 'email') || isValidUser(user, value, 'username'));

const membersCount = computed(() => {
  let count = 0;
  const members = nodeDetails.value?.members || [];
  members.forEach((member) => {
    if (getUser(member)) {
      count++;
    }
  });
  return count;
});
</script>

<style lang="scss" src="./TreeNodeTemplate.scss" />
