<template>
  <Teleport to="#general-modal">
    <div class="modal-overlay">
      <div class="modal-wrapper">
        <div class="confirmation-text">
          <div>
            {{ $t('routines.deleteRoutine') }}
          </div>
          <div class="confirmation-text-disclaimer">
            {{ $t('routines.access') }}
          </div>
        </div>
        <div class="confirmation-buttons">
          <iob-button
            :label="$t('routines.delete')"
            size="medium"
            color="alert"
            type="filled"
            @click="emit('delete')"
          />
          <iob-button
            :label="$t('routines.cancel')"
            type="filled"
            size="medium"
            color="secondarySoft"
            @click="emit('cancel')"
          />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>

const emit = defineEmits(['delete', 'cancel']);
</script>

<style lang="scss" scoped>
.modal-overlay {
    background: var(--modal-color-drawer-background, rgba(93, 88, 123, 0.50));
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    position: fixed;
    height: 100%;
}

.modal-wrapper {
    border-radius: var(--size-tokens-structure-pop-up-xsmall-radius, 8px);
    border: 1px solid var(--elevation-color-border, rgba(20, 19, 27, 0.00));
    background: var(--elevation-color-background, #FFF);

    /* shadow-for-leveling */
    box-shadow: var(--elevation-shadow-1-x, 0px) var(--elevation-shadow-1-y, 16px)
    var(--elevation-shadow-1-blur, 28px) var(--elevation-shadow-1-spread, 2px)
    var(--elevation-shadow-1-color, rgba(20, 19, 27, 0.05)),
    var(--elevation-shadow-2-x, 0px) var(--elevation-shadow-2-y, 8px)
    var(--elevation-shadow-2-blur, 28px) var(--elevation-shadow-2-spread, 4px)
    var(--elevation-shadow-2-color, rgba(20, 19, 27, 0.03)),
    var(--elevation-shadow-3-x, 0px) var(--elevation-shadow-3-y, 8px) var(--elevation-shadow-3-blur, 12px)
    var(--elevation-shadow-3-spread, -4px) var(--elevation-shadow-3-color, rgba(20, 19, 27, 0.07));
}
.confirmation {
    &-text {
        padding: 32px 32px 8px 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: var(--gray-800, #333044);
        /* text-default/font-semibold */
        font-family: "Noto Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: -0.16px;

        &-disclaimer {
            color: var(--gray-500, #787391);
            /* text-s/font-regular */
            font-family: "Noto Sans", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
            letter-spacing: 0.14px;
        }
    }

    &-buttons {
        display: flex;
        flex-direction: row-reverse;
        gap: 16px;
        padding: 24px;
    }
}
</style>
