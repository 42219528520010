<template>
  <div class="HomePage content-container">
    <div class="HomePage-header content-header">
      <iob-header-action
        :title="`${$t('home.hello')} ${userfirstname} &#128075;`"
        :grid-view-button="false"
        :list-view-button="false"
        :filter-button="false"
        :sort-button="false"
        :show-button="false"
        :has-search-input="false"
        :badge-text="currentLevel.label"
        :badge-color="currentLevel.color"
      />
    </div>
    <div class="content-body-container">
      <div class="HomePage-body content-body">
        <div class="HomePage-body-image-container">
          <img
            class="HomePage-body-image"
            :src="homeImage"
            alt="home"
          >
        </div>
        <div class="HomePage-body-routines-section">
          <div class="HomePage-body-title-section">
            <div class="HomePage-body-title-text">
              {{ $t('home.routines.label') }}
            </div>
            <div class="HomePage-body-title-button">
              <iob-button
                v-if="routineList.length"
                show-right-icon
                right-icon="Plus"
                @click="showRoutineModal"
              />
            </div>
          </div>
          <div class="HomePage-body-routine-list">
            <template v-if="isLoading && !routineList.length">
              <IobRoutineCard
                v-for="index in skelatonCount"
                :key="index"
                is-loading
              />
            </template>
            <IobRoutineCard
              v-else-if="!routineList.length"
              class="HomePage-body-create-routine"
              title="+Add New Routine"
              ghost
              @click="showRoutineModal"
            />
            <IobRoutineCard
              v-for="(card, index) in routineList"
              :key="index"
              :badge-text="card.badgeText"
              :title="card.title"
              :subtitle="card.subtitle"
              :ghost="false"
              :icon-kanji="card.iconKanji"
              :icon-name="card.iconName"
              :badge-color="card.badgeColor"
              :icon-color="card.iconColor"
              :actions="routineActions"
              @click="() => openURL(card.url)"
              @action-select="(index) => handleActionSelect(index, card.id)"
            />
          </div>
        </div>
      </div>
    </div>
    <RoutineModal
      v-if="isRoutineModalVisible"
      :edit-enabled="routineEditEnabled"
      :routine="routineEditObject"
      @cancel="hideRoutineModal"
    />
    <DeleteRoutineModal
      v-if="selectedRoutine"
      @cancel="selectedRoutine = null"
      @delete="deleteRoutine(selectedRoutine); selectedRoutine = null"
    />
  </div>
</template>

<script setup>
import { useUsersStore } from 'SRC/piniaStore/users/users';
import { ref, computed, watch, onMounted } from 'vue';
import homeImage from 'SRC/assets/illustrations/home.png';
import { useAppStore } from 'SRC/piniaStore/app/app';
import { useDataStore } from 'SRC/piniaStore/data/data';
import RoutineModal from './RoutineModals/RoutineModal.vue';
import { ROUTINE_ICONS } from 'SRC/globals/constants';
import DeleteRoutineModal from './RoutineModals/DeleteRoutineModal.vue';
import { useI18n } from 'vue-i18n';
const skelatonCount = 4;
const { t } = useI18n();
const usersStore = useUsersStore();
const appStore = useAppStore();
const dataStore = useDataStore();
const userfirstname = computed(() => usersStore.currentUser?.firstname || '');
const levels = computed(() => Object.values(appStore.levels).map((level) => (
  {
    id: level.id,
    text: level.attributes['level-name'],
    color: level.attributes['level-color']
  }
)));
const currentLevel = ref({label: 'level 3', color: 'primary'});
const isLoading = ref(true);
const isRoutineModalVisible = ref(false);
const routineEditEnabled = ref(false);
const routineEditObject = ref({});
const deleteRoutine = (id) => dataStore.deleteDatasetElement(id);
const routineDataTypeID = computed(() => Object.values(appStore.systemDatatypes).find((datatype) => datatype.name.toLowerCase() === 'routine')?.id);
const routineList = computed(() => Object.values(dataStore.datasetElements).reduce((list, datatype) => {
  if (datatype.typeId === routineDataTypeID.value) {
    list.push({
      title: datatype.attributes.name,
      subtitle: datatype.attributes.description,
      badgeText: levels.value.find((level) => datatype.attributes.level === level.id)?.text || '',
      badgeColor: 'primary',
      iconKanji: ROUTINE_ICONS[datatype.attributes.icon]?.backgroundImg,
      iconColor: ROUTINE_ICONS[datatype.attributes.icon]?.color,
      iconName: datatype.attributes.icon,
      url: datatype.attributes.url,
      id: datatype.id
    });
  }
  return list;
}, []).sort((a, b) => b.badgeText.localeCompare(a.badgeText)));
const selectedRoutine = ref(null);

const routineActions = [{
  text: t('routines.edit'),
  iconName: 'SquarePen',
  type: 'menu'
},
{
  text: t('routines.delete'),
  iconName: 'Trash',
  type: 'menu'
}];

function handleActionSelect(index, routineId) {
  if (index === 0) {
    if (routineId in dataStore.datasetElements) {
      const routine = { routineId, ...dataStore.datasetElements[routineId].attributes };
      showRoutineModal({editEnabled: true, routine});
    }
  } else if (index === 1) {
    selectedRoutine.value = routineId;
  }
}

const showRoutineModal = ({editEnabled = false, routine = {}} = {}) => {
  routineEditEnabled.value = editEnabled;
  routineEditObject.value = routine;
  isRoutineModalVisible.value = true;
};

const hideRoutineModal = () => {
  isRoutineModalVisible.value = false;
};

watch(appStore.systemDatatypes,
  async () => {
    isLoading.value = true;
    await dataStore.fetchDatasetElements([routineDataTypeID.value], false, true);
    isLoading.value = false;
  }, {
    deep: true
  }
);

onMounted(() => {
  if (routineDataTypeID.value) {
    dataStore.fetchDatasetElements([routineDataTypeID.value], false, true);
  }
});

const openURL = (url) => {
  window.open(url, '_blank');
};

</script>

<style lang="scss" scoped>
.HomePage {

  &-body {

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;

      &-container {
        width: 100%;
        height: 300px;
      }
    }

    &-routines-section {
      display:flex;
      flex-direction: column;
      gap:16px;
    }

    &-title {
      &-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
      }

      &-text {
        overflow: hidden;
        color: var(--app-section-color-title, #1C1A25);
        text-overflow: ellipsis;
        font-family: "Markazi Text", sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.32px;
      }
    }
    &-routine-list {
      display: grid;
      gap: 16px;
    }
    &-create-routine {
      width: 344px;
      height: 148px;
      cursor: pointer;
    }
  }
}

/* Large Desktop */
@media (min-width: 1420px) {
  .HomePage-body-routine-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Desktop */
@media (min-width: 1024px) and (max-width: 1419.99px) {
  .HomePage-body-routine-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Tablet */
@media (min-width: 640px) and (max-width: 1023.99px) {
  .HomePage-body-routine-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Mobile */
@media (max-width: 639.99px) {
  .HomePage-body-routine-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

</style>
