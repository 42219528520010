<template>
  <div
    class="IobImageTextElement"
    :style="styleObject"
    :class="classList"
    @click="handleElementClick"
  >
    <div
      :tabindex="isClickable ? 0 : -1"
      class="IobImageTextElement-image focus-inside"
    >
      <div
        v-if="imgSrc === ''"
        style="background-color: #fff"
      />
      <iob-image
        v-else
        class="IobImageTextElement-image-main"
        :src="imgSrc"
        :render-type="imageRatio"
      />
      <div class="IobImageTextElement-image-veil">
        <icon-loader
          v-if="!isCheckable"
          color="#ffffff"
          size="large"
          name="Eye"
        />
      </div>
    </div>
    <div class="IobImageTextElement-content">
      <div class="IobImageTextElement-content-text">
        {{ text }}
      </div>
      <iob-checkbox
        v-if="isCheckable"
        id="checkbox"
        :checked="imageChecked"
        type="rounded"
      />
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, computed, ref, watchEffect} from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobImage from '../../Atoms/IobImage/IobImage.vue';
import IobCheckbox from '../../Atoms/IobCheckbox/IobCheckbox.vue';

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  imgSrc: {
    type: String,
    default: ''
  },
  imageRatio: {
    type: String,
    default: 'fit-to-box'
  },
  isClickable: {
    type: Boolean,
    default: false
  },
  isCheckable: {
    type: Boolean,
    default: false
  },
  checked: {
    type: Boolean,
    default: false
  }
});

const imageChecked = ref(props.checked);
watchEffect(() => {
  imageChecked.value = props.checked;
});

const classList = computed(() => ({
  'IobImageTextElement--clickable': props.isClickable
}));

const styleObject = computed(() => ({
  height: props.imageRatio === 'fit-to-box' ? '100%' : 'auto'
}));

const emits = defineEmits(['click']);

const handleElementClick = () => {
  if (props.isClickable) {
    emits('click');
  }
  if (props.isCheckable) {
    if (!imageChecked.value) {
      imageChecked.value = true;
    }
    emits('check', imageChecked.value);
  }
}

</script>

<style lang="scss" scoped>
.IobImageTextElement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--imageText-gap-image-title, 4px);
  width: 100%;

  &-image-veil {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(93, 88, 123, 0.5);
    opacity: 0;
  }

  &--clickable:hover {
    outline: none;
    .IobImageTextElement-image {
      border: 1px solid var(--focus-color-focus);
        &-veil {
          opacity: 1;
        }
    }

    .IobImageTextElement-content {
      &-text {
        color: var(--imageText-color-text-title-hover, #1D51CE);
      }

      ::v-deep(svg *) {
        fill: var(--imageText-color-text-title-hover, #1D51CE);
      }
    }
  }

  &--clickable {
    cursor: pointer;
  }
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    &-text {
      color: var(--imageText-color-text-title-default);
      font-family: "Noto Sans", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.12px;
      overflow: hidden;
    }
  }
  &-image {
    min-height: var(--imageText-sizing-image-height, 96px);
    width: 100%;
    border-radius: var(--imageText-radius-image, 8px);
    border: var(--imageText-sizing-border-width, 1px) solid var(--imageText-color-border-default, rgba(20, 19, 27, 0.16));
    position: relative;
    overflow: hidden;

    &-main {
      height: 100%;
      width: 100%;
    }

    &:focus {
        .IobImageTextElement-image-veil {
          opacity: 1;
        }

        &+.IobImageTextElement-content {

          .IobImageTextElement-content-text {
            color: var(--imageText-color-text-title-hover, #1D51CE);
          }

          ::v-deep(svg *) {
            fill: var(--imageText-color-text-title-hover, #1D51CE);
          }
        }
      }
  }

}
</style>
