<template>
  <div v-if="appStore.exceptions.length">
    <dialog-box
      class="z-50"
      :dialog-box-specific-style="dialogBoxStyle"
      :no-padding="true"
    >
      <pop-up
        :details-object="appStore.exceptions[0]"
        title="Something went wrong"
        @popup-button-click="handleException"
      />
    </dialog-box>
  </div>
</template>

<script>
import { API_HOST } from 'SRC/config/api';
import { mapStores } from 'pinia';
import { useAppStore } from 'SRC/piniaStore/app/app';
export default {
  name: 'ExceptionHandler',
  data() {
    return {
      dialogBoxStyle: 'position: fixed; left: 35%; top: 35%; width: 35%;',
      API_HOST
    };
  },
  computed: {
    ...mapStores(useAppStore)
  },
  methods: {
    handleException() {
      const exceptions = this.appStore.exceptions;
      exceptions.shift();
      this.appStore.setExceptions(exceptions);
    }
  }
};
</script>
